import {
  addOrgIdRequestInterceptor,
  handleResponseStatusInterceptor
} from '@src/api';

import { Api as BeaconApi } from './beacon/api';
import { Api as ClerkApi } from './clerk/api';
import { Api as DexterApi } from './dexter/api';
import { Api as SeekerApi } from './seeker/api';

const beaconApi = new BeaconApi({
  baseURL: import.meta.env.VITE_BEACON,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
});

const dexterApi = new DexterApi({
  baseURL: import.meta.env.VITE_DEXTER,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
});

const clerkApi = new ClerkApi({
  baseURL: import.meta.env.VITE_CLERK,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
});

const seekerApi = new SeekerApi({
  baseURL: import.meta.env.VITE_SEEKER,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
});

beaconApi.instance.interceptors.request.use(
  addOrgIdRequestInterceptor,
  error => {
    return Promise.reject(error);
  }
);

dexterApi.instance.interceptors.request.use(
  addOrgIdRequestInterceptor,
  error => {
    return Promise.reject(error);
  }
);

clerkApi.instance.interceptors.request.use(
  addOrgIdRequestInterceptor,
  error => {
    return Promise.reject(error);
  }
);

seekerApi.instance.interceptors.request.use(
  addOrgIdRequestInterceptor,
  error => {
    return Promise.reject(error);
  }
);

beaconApi.instance.interceptors.response.use(response => {
  return response;
}, handleResponseStatusInterceptor);

dexterApi.instance.interceptors.response.use(response => {
  return response;
}, handleResponseStatusInterceptor);

clerkApi.instance.interceptors.response.use(response => {
  return response;
}, handleResponseStatusInterceptor);

seekerApi.instance.interceptors.response.use(response => {
  return response;
}, handleResponseStatusInterceptor);

export const apiClient = {
  beacon: beaconApi.v1,
  dexter: dexterApi.v1Alpha1,
  clerkApi: clerkApi.api,
  seekerApi: seekerApi.chat
};
