import { createSlice } from '@reduxjs/toolkit';

const getInitialState = () => {
  const activeProject = {};
  const activeAoi = {};

  return {
    isProjectListLoading: false,
    projectList: [],
    showEditAoiImageList: false,
    showProjectList: false,
    activeProject,
    activeAoi,
    recentProjects: [],
    aois: {},
    insights: {
      activeInsight: {},
      activeModel: {},
      vectorDataLoader: false
    },
    visualize: {
      splitMode: false,
      isMapCompare: false,
      splitConfig: {
        left: {
          leftDate: '',
          leftPreview: '',
          colorscale: {},
          imageLayers: []
        },
        right: {
          rightDate: '',
          rightPreview: '',
          colorscale: {},
          imageLayers: []
        }
      },
      // new spectral state will be maintained in context
      spectralSignature: {
        graphMode: 'single',
        spectralPoint: [],
        spectralPoint2: [],
        spectralData: {},
        spectralData2: {},
        selectedPoint: 'Point A'
      },

      // everything below this will be deprecated
      selectedDate: '',
      showComposite: false,
      selectedBand: '',
      selectedComposite: {
        Red: '',
        Green: '',
        Blue: ''
      },
      customIndice: '',
      selectedIndice: '',
      selectedLayer: {},
      mode: 'Indices'
    }
  };
};

export const projectSlice = createSlice({
  name: 'projects',
  initialState: getInitialState(),
  reducers: {
    setDate: (state, action) => {
      state.visualize.selectedDate = action.payload;
    },
    setActiveProject(state, action) {
      state.activeProject = action.payload;
    },
    setActiveAoi: (state, action) => {
      state.activeAoi = action.payload;
    },
    setProjectList: (state, action) => {
      state.projectList = action.payload;
    },
    setIsProjectListLoading: (state, action) => {
      state.isProjectListLoading = action.payload;
    },
    setSelectedBand: (state, action) => {
      state.visualize.selectedBand = action.payload;
    },
    setRedBand: (state, action) => {
      state.visualize.selectedComposite.Red = action.payload;
    },
    setGreenBand: (state, action) => {
      state.visualize.selectedComposite.Green = action.payload;
    },
    setBlueBand: (state, action) => {
      state.visualize.selectedComposite.Blue = action.payload;
    },
    setSelectedIndice: (state, action) => {
      state.visualize.selectedIndice = action.payload;
    },
    setSelectedLayer: (state, action) => {
      state.visualize.selectedLayer = action.payload;
    },
    clearSelectedLayer: state => {
      state.visualize.selectedLayer = {};
    },
    clearRestOfVisualize: state => {
      state.visualize.selectedBand = '';
      state.visualize.selectedIndice = '';
      state.visualize.mode = 'layers';
    },
    setCustomIndice: (state, action) => {
      state.visualize.customIndice = action.payload;
    },
    setTileMode: (state, action) => {
      state.visualize.mode = action.payload;
    },
    changeLayerVisibility: (state, action) => {
      state.layers.map((layer, index) => {
        if (layer.id === action.payload) {
          if (state.layers[index].visibility) {
            state.layers[index].visibility = false;
          } else state.layers[index].visibility = true;
        }
      });
    },
    showComposite: (state, action) => {
      state.visualize.showComposite = action.payload;
    },
    // toggleProjectList: (state, action) => {
    //   state.showProjectList = action.payload;
    // },
    populateSpectralData: (state, action) => {
      state.visualize.spectralSignature.spectralData = action.payload;
    },
    populateSpectralData2: (state, action) => {
      state.visualize.spectralSignature.spectralData2 = action.payload;
    },
    setSpectralPoint: (state, action) => {
      state.visualize.spectralSignature.spectralPoint = action.payload;
    },
    setSpectralPoint2: (state, action) => {
      state.visualize.spectralSignature.spectralPoint2 = action.payload;
    },
    setSpectralMode: (state, action) => {
      state.visualize.spectralSignature.graphMode = action.payload;
    },
    setSelectedPoint: (state, action) => {
      state.visualize.spectralSignature.selectedPoint = action.payload;
    },
    toggleSplitMode: state => {
      state.visualize.splitMode = !state.visualize.splitMode;
    },
    setSplitMode: (state, action) => {
      state.visualize.splitMode = action.payload;
    },
    setIsMapCompare: (state, action) => {
      state.visualize.isMapCompare = action.payload;
    },
    setLeftDate: (state, action) => {
      state.visualize.splitConfig.left.leftDate = action.payload;
    },
    setRightDate: (state, action) => {
      state.visualize.splitConfig.right.rightDate = action.payload;
    },
    setLeftUrl: (state, action) => {
      state.visualize.splitConfig.left.leftPreview = action.payload;
    },
    setRightUrl: (state, action) => {
      state.visualize.splitConfig.right.rightPreview = action.payload;
    },
    setLeftImageLayers: (state, action) => {
      state.visualize.splitConfig.left.imageLayers = action.payload;
    },
    setRightImageLayers: (state, action) => {
      state.visualize.splitConfig.right.imageLayers = action.payload;
    },
    setLeftColorScale: (state, action) => {
      state.visualize.splitConfig.left.colorscale = action.payload;
    },
    setRightColorScale: (state, action) => {
      state.visualize.splitConfig.right.colorscale = action.payload;
    },

    // For insights & inferences
    setActiveInsight: (state, action) => {
      state.insights.activeInsight = action.payload;
    },
    setActiveModel: (state, action) => {
      state.insights.activeModel = action.payload;
    },
    setInsightGeojson: (state, action) => {
      if (state.insights.activeInsight?.geojson) {
        state.insights.activeInsight.geojson = action.payload;
      }
    },
    setInsightLabelVisibility: (state, action) => {
      if (state.insights.activeInsight?.showLabels !== undefined) {
        state.insights.activeInsight.showLabels = action.payload;
      }
    },
    setHoverID: (state, action) => {
      if (state.insights.activeInsight?.hoverID !== undefined) {
        state.insights.activeInsight.hoverID = action.payload;
      }
    },
    setActiveRaster: (state, action) => {
      if (state.insights.activeInsight?.activeRaster !== undefined) {
        state.insights.activeInsight.activeRaster = action.payload;
      }
    },
    // for change detection specific use case
    setViewMode: (state, action) => {
      if (state.insights.activeInsight?.viewMode !== undefined) {
        state.insights.activeInsight.viewMode = action.payload;
      }
    },
    adjustRasterOpacity: (state, action) => {
      if (state.insights.activeInsight?.[action.payload.key] !== undefined) {
        state.insights.activeInsight[action.payload.key] =
          action.payload.opacity;
      }
    },
    setCurrentChildClasses: (state, action) => {
      if (state.insights.activeInsight?.childClasses !== undefined) {
        state.insights.activeInsight.childClasses = action.payload;
      }
    },
    setImageLayers: (state, action) => {
      if (state.insights.activeInsight?.imageLayers !== undefined) {
        state.insights.activeInsight.imageLayers = action.payload;
      }
    },
    // others
    setVectorDataLoader: (state, action) => {
      state.insights.vectorDataLoader = action.payload;
    },
    setShowEditAoiImageList: (state, action) => {
      state.showEditAoiImageList = action.payload;
    },
    updateSasToken: (state, action) => {
      state.insights.list.map((insight, i) => {
        if (insight.id === action.payload.id) {
          state.insights[i].sasToken = action.payload.sasToken;
        }
      });
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  setDate,
  setActiveInsight,
  setActiveModel,
  setInsightGeojson,
  setHoverID,
  setInsightLabelVisibility,
  setViewMode,
  setActiveRaster,
  adjustRasterOpacity,
  setVectorDataLoader,
  setActiveProject,
  setActiveAoi,
  setSelectedBand,
  setRedBand,
  setGreenBand,
  setBlueBand,
  setSelectedIndice,
  setTileMode,
  changeLayerVisibility,
  setSelectedLayer,
  clearSelectedLayer,
  clearRestOfVisualize,
  showComposite,
  populateSpectralData,
  populateSpectralData2,
  setSpectralPoint,
  setSpectralPoint2,
  setSpectralMode,
  setSelectedPoint,
  toggleSplitMode,
  setSplitMode,
  setIsMapCompare,
  setLeftDate,
  setRightDate,
  setCustomIndice,
  setShowEditAoiImageList,
  updateSasToken,
  setLeftUrl,
  setRightUrl,
  setLeftImageLayers,
  setRightImageLayers,
  setLeftColorScale,
  setRightColorScale,
  setProjectList,
  setIsProjectListLoading,
  setCurrentChildClasses,
  setImageLayers
} = projectSlice.actions;

export default projectSlice.reducer;
